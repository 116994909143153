import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container } from 'react-bootstrap'

const Policy = ({location}) => {

  const data = useStaticQuery(graphql`
    query {
      policy: file(relativePath: { eq: "policy.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
    }
  `)

  return(
    <Layout location={location} title="経営理念" >
      <SEO title="経営理念" description="お客様の経営資源（ヒト･モノ･カネ･情報）をつなぎ、新しい価値を創造します｡"/>

      <div className="page-head">
				<Container expand="lg">
					<h2>経営理念</h2>
					<p>お客様の経営資源（ヒト･モノ･カネ･情報）をつなぎ、新しい価値を創造します｡</p>
				</Container>
				<div className="bottom-border" />
			</div>

      <Container expand="lg">
        <h3 className="text-center mb-5">会社ポリシー「つなぐ会社」</h3>
        <Img fluid={data.policy.childImageSharp.fluid} className="mx-auto" alt="経営理念"/>
        <p className="text-left text-lg-center mt-5">
          社会、会社には1つ1つ独⽴、完結している「モノ」、「事象」、「情報」が多くあります。<br/>
          ⽣活している中で「情報がまとまってない、散らばって伝わっていて2度⼿間」ということもありませんか︖<br className="d-lg-block d-none" />
          それらが、適切に「つながる」ことで、より便利になるのにと考えたことも⼀度はあるのではないでしょうか。<br/>
          私たちグローバルワイズは「つなぐ」ことをキーワードに事業活動を⾏っています。<br/>
          社会に存在する様々なモノ、情報、⽣活するヒト、存在する会社、などを<br className="d-lg-block d-none" />
          EDI、IoT、クラウドなど進化し続けるITテクノロジーを⽤いて適切に「つなぐ」ことにより、お客様のDXを推進します。<br/>
          社会に新しい価値を提供し、貢献していくことを⽬指しています。
        </p>
      </Container>
    </Layout>
  )
}

export default Policy